.questionNav{
    text-decoration: none;
    width: 120px;
    height: 120px;
    text-align: center;
    border: 1px solid gray;
    border-radius: 6px;
    color: gray;
    align-items: center;
    vertical-align: middle;
    background-color: rgba($color: #000000, $alpha: 0);

    div{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    &:visited{
        color:gray;
        
    }

    &:hover{
        background-color: rgba($color: #000000, $alpha: 0.1);
    }
}

.questionFlex{
    width: 60%;
}

.disabledLink {
    pointer-events: none;
    color: lightgray;
    border-color: lightgray;
}

@media only screen and (max-width: 600px) {
    .questionFlex{
        width: calc(100% - 20px);
    }
  }