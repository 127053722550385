.bar {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  padding-left: 20px;
  gap: 10px;
  height: 100%;
}

.left {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  padding-left: 20px;
  gap: 10px;
  height: 100%;
}

.right {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding-left: 20px;
    gap: 10px;
    height: 100%;

    p{
        color: lightgray;
    }
  }

@media only screen and (max-width: 600px) {
  .bar {
    justify-content: center;
  }

  
.right {
    visibility: hidden;
  }
}

.navLink {
  height: 100%;
  text-decoration: none;
  color: darkslategray;
  background-color: rgba($color: #000000, $alpha: 0);
  padding: 10px;
  display: inline-flex;
  align-items: center;

  &:visited {
    color: darkslategray;
  }
  &:hover {
    background-color: rgba($color: #000000, $alpha: 0.05);
  }
}
