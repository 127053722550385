.sign{
    max-width: 200px;
    max-height: 200px;
}

.default{
    max-width: 480px;
}
@media only screen and (max-width: 600px) {
    .sign{
        max-width: min(calc(90% - 10px), 200px);
        max-height: 200px;
    }

    .default{
        max-width: calc(90% - 10px);
    }
  }